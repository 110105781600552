import React from 'react';
import _trimStart from 'lodash/trimStart';
import querystring from 'querystring';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isLoading } from '../behaviors/loading';
import { isError } from '../behaviors/error';
import {
  getProfileById,
  fetchProfileV8,
  fetchProfile
} from '../behaviors/profiles';
import { configSelector } from '../behaviors/configuration';
import { tokensSelector } from '../behaviors/tokens';
import { getCustomerCode } from '../behaviors/customer-code';
import { deviceSelector } from '../behaviors/device';

import Profile from '../profile/index';
import ProfileV9 from '../profile-v9/index';
import { decorateProfileTracking } from './logging-decorators';
import {
  fetchSlots,
  fetchPurposesByProviderId
} from '../behaviors/availability';

import { scrollToHashIfExists } from '../utils/scroll';
import { settingsSelector } from '../behaviors/settings';

import {
  getProviderProfilePath,
  getProviderProfilePathV8
} from 'Common/urls/provider';

const mapStateToProps = (state, ownProps) => {
  const providerId = Number(ownProps.match.params.id);
  const config = configSelector(state);
  const profile = getProfileById(state, providerId);
  const loading = isLoading(state);
  const error = isError(state);
  const tokens = tokensSelector(state);
  const customerCode = getCustomerCode(state);
  const currentQuery = querystring.parse(
    _trimStart(ownProps.location.search, '?')
  );
  const device = deviceSelector(state);
  const appSettings = settingsSelector(state);

  // TODO: remove when we complete search V9 migration; defaults to false
  const useProfileSearchV9 = Boolean(config.darkship_use_profile_searchv9);
  const { searchSDK } = ownProps;

  const log = (message, event_data = {}) => {
    const additionalTrackingData = decorateProfileTracking({
      state,
      routeMatch: ownProps.match
    });

    ownProps.log(message, { ...event_data, ...additionalTrackingData });
  };

  return {
    config,
    profile,
    currentQuery,
    loading,
    error,
    log,
    tokens,
    providerId,
    customerCode,
    device,
    useProfileSearchV9,
    searchSDK,
    availabilityLoading: state.availability.loading,
    availabilityError: state.availability.error,
    purposesLoading: state.availability.purposesLoading,
    slotsByProviderId: state.availability.slotsByProviderId,
    slotsStatus: state.availability.slotsStatusByProviderId,
    purposeOptionsByProviderId: state.availability.purposeOptionsByProviderId,
    availablePurposesByProviderId:
      state.availability.availablePurposesByProviderId,
    appSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchProfileV8, fetchProfile, fetchSlots, fetchPurposesByProviderId },
    dispatch
  );
};

class ProfileContainerClass extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
  }

  componentDidMount() {
    const {
      profile,
      fetchProfileV8,
      fetchProfile,
      location,
      config,
      providerId,
      customerCode,
      useProfileSearchV9,
      searchSDK
    } = this.props;

    window.scrollTo(0, 0);
    if (!profile) {
      if (useProfileSearchV9) {
        fetchProfile({
          providerId,
          customerCode,
          config,
          searchSDK
        });
      } else {
        fetchProfileV8(location);
      }
    }
    /* TODO: we may be able to remove this timeout after issues from 
    https://healthsparq.atlassian.net/browse/KENG-42044 are resolved */
    this.timer = setTimeout(() => {
      scrollToHashIfExists(location.hash);
    }, 500);
  }

  componentDidUpdate(previousProps) {
    const {
      location,
      fetchProfileV8,
      profile,
      config,
      providerId,
      customerCode,
      useProfileSearchV9,
      searchSDK
    } = this.props;

    if (location.pathname !== previousProps.location.pathname) {
      window.scrollTo(0, 0);
      if (useProfileSearchV9) {
        fetchProfile({
          providerId,
          customerCode,
          config,
          searchSDK
        });
      } else {
        fetchProfileV8(location);
      }
    }

    if (profile && profile !== previousProps.profile) {
      scrollToHashIfExists(location.hash);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { useProfileSearchV9 } = this.props;
    if (useProfileSearchV9) {
      // return v9
      return <ProfileV9 {...this.props} />;
    }
    // return v8
    return <Profile {...this.props} />;
  }
}

ProfileContainerClass.prototype.serverSideExecute =
  async function serverSideExecute({ req, res, store }) {
    const {
      fetchProfileV8,
      fetchProfile,
      location,
      config,
      providerId,
      customerCode,
      useProfileSearchV9,
      searchSDK
    } = this.props;

    if (useProfileSearchV9) {
      await fetchProfile({
        providerId,
        customerCode,
        config,
        searchSDK,
        req
      });
      const state = store.getState();

      const profile = getProfileById(state, providerId);
      processProfileV9({ location, res, profile });
    } else {
      await fetchProfileV8(location, req);
      const state = store.getState();
      const profile = getProfileById(state, providerId);
      processProfileV8({ location, res, profile });
    }
  };

// exported for unit tests
export function processProfileV8({ location, res, profile }) {
  if (!profile) {
    res.status(404);
  } else {
    // this provider's canonical profile url
    const expectedProfilePath = getProviderProfilePathV8(profile.profile);
    if (expectedProfilePath !== location.pathname) {
      // redirect to the canonical. Redirecting allows the canonical page
      // to get the seo juice of the previous url
      // https://developers.google.com/search/docs/crawling-indexing/301-redirects
      res.redirect(301, `${expectedProfilePath}${location.search || ''}`);
    }
  }
}

export function processProfileV9({ location, res, profile }) {
  if (!profile) {
    // render 404 if search returned no results
    res.status(404);
  } else {
    // this provider's canonical profile url
    const expectedProfilePath = getProviderProfilePath(profile.provider);
    if (expectedProfilePath !== location.pathname) {
      // redirect to the canonical. Redirecting allows the canonical page
      // to get the seo juice of the previous url
      // https://developers.google.com/search/docs/crawling-indexing/301-redirects
      res.redirect(301, `${expectedProfilePath}${location.search || ''}`);
    }
  }
}

const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileContainerClass);

export default ProfileContainer;
