import React, { useCallback } from 'react';
import querystring from 'querystring';
import { ProviderProfile } from '@kyruus/provider-profile';
import { injectIntl } from '@kyruus/intl';

import { ProfileWrapper, ProfileContainer } from './styles';
import SearchLinks from './search-links';
import LoadingOverlay from '../shared/loading';
import PoweredByLogo from '../shared/powered-by-logo';
import Error from '../error';
import ProfileMetas from './profile-metas';

import { messages } from './lib/messages';
import { SLOTS_LOADING_STATUS } from '../utils/constants';
import { buildLocationURL, locationsModuleEnabled } from '../utils/location';
import { isCCF } from '../utils/cerner';
import { canSkipModal } from './lib/utils';
import { getRelativeParameterizedBookingUrl } from '../utils/getRelativeParameterizedBookingUrl';
import {
  useTracking,
  useProfileCtaModal,
  useProfileAvailability
} from './lib/hooks';
import { isApptInfoValid, shouldRenderBookingBtnAsLink } from './lib/utils';
import { getProviderSummaryUrl } from '../provider/utils';
import {
  MODULES,
  isModuleEnabled,
  shouldRenderDirectBookInDrawer
} from 'Common/config';

const ProfileV9 = ({
  providerId,
  profile,
  currentQuery,
  customerCode,
  config,
  loading,
  error,
  log,
  location,
  fetchPurposesByProviderId,
  fetchSlots,
  slotsByProviderId,
  slotsStatus: slotsStatusByProviderId,
  availabilityError,
  availabilityLoading,
  purposeOptionsByProviderId,
  purposesLoading,
  history,
  device,
  intl,
  match,
  appSettings
}) => {
  useTracking({ log, config, providerId, profile, error, loading });

  const {
    provider,
    locations,
    availability,
    altProviders,
    careTeamProviders,
    mlocationsById
  } = profile || {};

  const urlQuerystring = location.search;
  const queryParams = querystring.parse(
    urlQuerystring.substring(1, urlQuerystring.length)
  );
  const searchFilters = Array.isArray(queryParams.filter)
    ? queryParams.filter
    : [queryParams.filter].filter(Boolean); // if queryParams.filter was null-ish it gets filtered out
  const shouldOpenDirectBookSameWindow = isModuleEnabled(
    config,
    MODULES.DIRECT_BOOK
  );
  const bookingLinkTarget = shouldOpenDirectBookSameWindow ? '_self' : null;
  const { modal_display } = config;
  const isVirtualCare = modal_display?.virtual_care;

  const slots =
    slotsByProviderId && slotsByProviderId[providerId]
      ? slotsByProviderId[providerId]?.slots
      : null;
  const initApptInfo = (() => {
    if (slotsByProviderId[providerId]?.apptInfo) {
      return slotsByProviderId[providerId]?.apptInfo;
    }

    if (queryParams.relationship && queryParams.purpose) {
      return {
        relationship: queryParams.relationship,
        purpose: queryParams.purpose
      };
    }

    return undefined;
  })();
  const slotsStatus =
    slotsStatusByProviderId && slotsStatusByProviderId[providerId]
      ? slotsStatusByProviderId[providerId]
      : SLOTS_LOADING_STATUS;

  /**
   * Creates an availability URL for the given alt provider or care team provider.
   * @param {Object} provider - The provider object.
   * @returns {string|null} The availability URL or null if provider is not provided.
   */
  const createAvailabilityUrl = (provider) => {
    if (!provider) {
      return null;
    }

    return (
      getRelativeParameterizedBookingUrl({
        provider,
        config,
        location,
        apptOptions: {},
        ignoreApptOptions: true
      }) || provider.virtual_care_url
    );
  };

  /*
   * Returns the URL that the user will be directed to when booking.
   * Used as the `href` for buttons that will be handled by the callback,
   * or the anchor tag when customer config has button be a direct link.
   */
  const getDirectBookHref = (provider) => {
    if (!provider) {
      return null;
    }

    const relativeParameterizedBookingUrl = getRelativeParameterizedBookingUrl({
      provider,
      config,
      location,
      apptOptions: initApptInfo
    });

    if (relativeParameterizedBookingUrl) {
      return relativeParameterizedBookingUrl;
    }

    return provider.virtual_care_url;
  };
  const directBookHref = getDirectBookHref(provider);
  const { profileCtaModal, setProfileCtaModalIsOpen } = useProfileCtaModal({
    profile,
    bookingLinkTarget,
    directBookHref,
    isVirtualCare,
    config,
    log
  });

  const {
    availabilityByLocationId,
    availabilityTileFactory,
    availabilityApptInfoModal,
    editApptInfoCallback,
    patientRelOptions,
    apptInfo,
    bookingInfoLoading
  } = useProfileAvailability({
    availabilityError,
    availabilityLoading,
    config,
    directBookHref,
    fetchPurposesByProviderId,
    fetchSlots,
    history,
    intl,
    location,
    log,
    profile,
    providerId,
    purpose: initApptInfo?.purpose,
    purposeOptionsByProviderId,
    relationship: initApptInfo?.relationship,
    slots,
    slotsStatus
  });

  const bookingCallback = useCallback(() => {
    if (shouldRenderDirectBookInDrawer(config)) {
      history.push(directBookHref, { provider: { location, provider } });
    } else if (!canSkipModal(provider, config)) {
      setProfileCtaModalIsOpen(true);
    }
  }, [
    log,
    config,
    provider,
    history,
    directBookHref,
    location,
    setProfileCtaModalIsOpen
  ]);

  const locationCallback = useCallback(
    (locationUrl, isExternalUrl = false) => {
      log('user_action.display_location_profile_page');

      if (isExternalUrl && typeof window !== 'undefined') {
        window.open(locationUrl, '_blank', 'noopener');
      }
      if (!isExternalUrl) {
        history.push(locationUrl);
      }
    },
    [history, log]
  );

  if (!loading && (error || !profile)) {
    return (
      <Error
        config={config}
        customerCode={customerCode}
        history={history}
        log={log}
      />
    );
  }

  return (
    <ProfileWrapper>
      <ProfileContainer>
        <LoadingOverlay loading={loading} />
        {profile && (
          <ProfileMetas profile={profile} config={config} messages={messages} />
        )}
        <SearchLinks
          currentQuery={currentQuery}
          log={log}
          profile={profile}
          config={config}
          device={device}
        />
        {!loading && profile && (
          <ProviderProfile
            /*
             * The patient relationship and appointment purpose for the provider's availability tiles.
             * This data indicates the user has searched by appointment, and will be used in displaying
             * the availability view
             */
            apptInfo={isApptInfoValid(apptInfo) ? apptInfo : undefined}
            /**
             * A map of location id's to their availability. Used to determine which locations to render availability for.
             * If undefined, assume we are in a loading or error state and display all locations.
             */
            availabilityByLocationId={availabilityByLocationId}
            /** Function to return an Availability Tile component */
            // @kyruus/provider-profile won't render availability tiles if this prop is undefined
            availabilityTileFactory={
              config.display_availability_in_search
                ? availabilityTileFactory
                : undefined
            }
            /** Callback provided to the profile to with the new appointment info */
            editApptInfoCallback={editApptInfoCallback}
            /** Callback provided to the profile to handle users clicking the booking button */
            bookingCallback={bookingCallback}
            /**
             * The href for the booking CTA when it's rendered as an anchor tag
             * If this prop is undefined, the booking CTA will be rendered as a button
             */
            bookOnlineUrl={
              shouldRenderBookingBtnAsLink(provider, config) && directBookHref
                ? directBookHref
                : undefined
            }
            /** The PMC config object, used for controlling certain behaviors of the profile */
            config={config}
            /** The locations array for the provider */
            locations={locations}
            /** The provider to display the profile of. */
            provider={provider}
            /** The availability_density_best value for this provider (https://kyruus.jira.com/wiki/spaces/AHC/pages/940441689/Feature+Availability+Density) */
            availabilityDensityBest={
              availability.meta.availability_density_best
            }
            /** The UTC date of the provider's soonest appointment */
            soonestAppointmentDate={availability.meta.min_date_available}
            /** Track function for analytics */
            track={log}
            /** Search filters the user has applied (passed from PMC) */
            filters={searchFilters}
            /** Function to build a provider's location's url */
            getLocationUrl={(l) =>
              buildLocationURL(
                locationsModuleEnabled(config),
                l,
                urlQuerystring
              )
            }
            /** Callback for when a provider's location anchor is clicked */
            locationCallback={locationCallback}
            /** Marketable locations for this provider */
            mlocations={mlocationsById}
            /** alternate providers to render (similar providers) */
            altProviders={altProviders}
            /** a provider's care team to render if enabled */
            careTeamProviders={careTeamProviders}
            /** function to create availability urls for each alt provider */
            createAvailabilityUrl={createAvailabilityUrl}
            /** function to create provider profile page urls for each alt provider */
            createProviderUrl={(provider) =>
              getProviderSummaryUrl(
                provider,
                '',
                slotsByProviderId,
                apptInfo,
                location,
                match
              )
            }
            shouldHidePageAnchors={isCCF()}
            /** whether or not to hide page anchor links */
            awsMapConfig={{
              identityPoolId:
                appSettings.AWS_MAPS_CREDENTIALS.mapIdentityPoolId,
              mapName: appSettings.AWS_MAPS_CREDENTIALS.mapName,
              region: appSettings.AWS_MAPS_CREDENTIALS.mapRegion
            }}
            /** provider purposes by relationship */
            purposeOptionsByRelationship={patientRelOptions}
            loading={{
              bookingInfo: bookingInfoLoading || purposesLoading
            }}
          />
        )}
        <PoweredByLogo config={config} />
      </ProfileContainer>
      {profileCtaModal}
      {availabilityApptInfoModal}
    </ProfileWrapper>
  );
};

export default injectIntl(ProfileV9);
