import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from '@kyruus/intl';
import _kebabCase from 'lodash/kebabCase';
import Print from '@kyruus/react-ikons/Print';

import SocialShareMenu, { IconWithLabel } from './social-share-menu';
import { messages as socialMessages } from '../profile/share-profile';
import { STICKY_QUERY_PARAMS } from '../utils/constants';
import { withQueryParam } from '../utils/url';
import { messages } from './lib/messages';
import { SearchLinksContainer, SocialWrapper, PrintOptions } from './styles';
import { getLinkDestination } from './lib/utils';

const SearchLink = ({ linkType, to, log }) => {
  const logClick = log
    ? () => log(`user_action.provider_detail.${linkType}`)
    : undefined;

  return (
    <Link id={_kebabCase(linkType)} to={to} onClick={logClick}>
      <FormattedMessage {...messages[linkType]} />
    </Link>
  );
};

const BackToSearchLink = ({ linkDestination, log }) => (
  <>
    <SearchLink linkType="return_to_results" to={linkDestination} log={log} />{' '}
    <FormattedMessage {...messages.or} />{' '}
  </>
);

const NewSearchLink = ({ currentQuery, config, log }) => {
  let newSearchLink = config.search_home || '/';
  STICKY_QUERY_PARAMS.forEach((param) => {
    if (param in currentQuery) {
      newSearchLink = withQueryParam(newSearchLink, param, currentQuery[param]);
    }
  });

  return <SearchLink linkType="new_search" to={newSearchLink} log={log} />;
};

const SearchLinks = ({ profile, currentQuery, config, log, device, intl }) => {
  const linkDestination = getLinkDestination({ currentQuery });

  const hasSearchQuery = !!Object.entries(currentQuery).length;
  const showPrint = !device.isWebview; /** disable print on webview */

  return (
    <SearchLinksContainer id="SearchLinksContainer">
      <div>
        {hasSearchQuery && (
          <BackToSearchLink
            linkDestination={linkDestination}
            log={log}
            messages={messages}
          />
        )}
        <NewSearchLink currentQuery={currentQuery} config={config} log={log} />
      </div>
      <SocialWrapper>
        {profile ? (
          <PrintOptions>
            <SocialShareMenu
              profile={profile}
              config={config}
              intl={intl}
              log={log}
            />
          </PrintOptions>
        ) : null}

        {showPrint && (
          <IconWithLabel
            data-testid="PrintProfileButton"
            icon={<Print tabIndex="-1" aria-hidden={true} size="21px" />}
            label={intl.formatMessage(socialMessages.printlabel)}
            onClick={() => print()}
            aria-label={intl.formatMessage(
              socialMessages.printProviderProfileAriaLabel
            )}
          />
        )}
      </SocialWrapper>
    </SearchLinksContainer>
  );
};

export default injectIntl(SearchLinks);
