import React, { useEffect } from 'react';

import {
  getProviderDisplayName_V8,
  specialtyNames_V8
} from '../provider/utils';

import {
  ProviderImage,
  ReviewSummary,
  ProviderBadges
} from '../provider/provider-summary';
import SchedulingOptions from '../provider/scheduling-options';

import ShareProfile from './share-profile';

function Summary({
  provider,
  config,
  log,
  currentQuery,
  fetchSlots,
  fetchPurposesByProviderId,
  slotsByProviderId,
  slotsStatus,
  availablePurposesByProviderId,
  location,
  tokens,
  device
}) {
  const hasSlotsInState =
    slotsByProviderId &&
    slotsByProviderId[provider.id] &&
    slotsByProviderId[provider.id].apptInfo;

  let apptInfo = null;
  if (hasSlotsInState) {
    ({ apptInfo } = slotsByProviderId[provider.id]);
  } else if (currentQuery.relationship && currentQuery.purpose) {
    // deep linking to profile instead of loading from state
    // const { relationship, purpose } = currentQuery;
    apptInfo = {
      relationship: currentQuery.relationship,
      purpose: currentQuery.purpose
    };
  }

  useEffect(() => {
    if (hasSlotsInState) {
      const { slots = [], apptInfo: { purpose, relationship } = {} } =
        slotsByProviderId[provider.id];

      if (slots.length) {
        // profile page only displays avail-tiles if slots are returned (no unavailable view on profile)
        // therefore, only log if slots are returned
        log('user_action.profile.availability_tiles_displayed', {
          provider_id: provider.id,
          slots: slots.length,
          purpose,
          relationship
        });
      }
    }
  }, [hasSlotsInState]);

  /*
      load slots if not in state...
      useEffect won't deeply compare objects;
      we need to pull the relevant props out
      to ensure the effect refires only when
      relationship/purpose change
    */
  const shouldDisplayAvailability = config.display_availability_in_search;
  const { relationship, purpose } = apptInfo || {};
  useEffect(() => {
    if (
      relationship &&
      purpose &&
      !hasSlotsInState &&
      shouldDisplayAvailability
    ) {
      fetchSlots(
        {
          relationship,
          purpose
        },
        [provider.id]
      );
    }
  }, [relationship, purpose, hasSlotsInState, shouldDisplayAvailability]);

  const specialties = specialtyNames_V8(provider);
  const SpecialtyList = specialties.length ? (
    <div className="col-xs-12 mb-s" role="list">
      <p className="fc-gray-2" id="specialty-list">
        {specialties.map((specialty, i) => {
          return (
            <span
              key={specialty}
              data-testid={`Specialty-${i}`}
              role="listitem"
            >
              <span itemProp="medicalSpecialty">{specialty}</span>
              {i === specialties.length - 1 ? '' : ', '}
            </span>
          );
        })}
      </p>
    </div>
  ) : null;

  return (
    <div
      id="provider-details-summary"
      className="row panel pt-m mb-m text-center"
      data-testid="ProviderSummary"
      role="region"
      aria-labelledby="provider-name"
    >
      <div className="col-xs-12 mb-s">
        <div className="row">
          <div className="col-xs-8 col-xs-offset-2 col-sm-8 col-sm-offset-2">
            <ProviderImage
              provider={provider}
              hash="#provider-details-about"
              isLarge={true}
            />
          </div>
        </div>
      </div>
      <div className="col-xs-12 mb-s">
        <h2 id="provider-name" itemProp="name">
          {getProviderDisplayName_V8(provider)}
        </h2>
      </div>
      {SpecialtyList}
      <ProviderBadges badges={provider.badges} />
      <ReviewSummary provider={provider} />
      <div className="col-xs-12 text-center">
        <SchedulingOptions
          provider={provider}
          config={config}
          log={log}
          buttonLocation="under_photo"
          apptInfo={apptInfo}
          fetchSlots={fetchSlots}
          slotsByProviderId={slotsByProviderId}
          slotsStatus={slotsStatus}
          availablePurposesByProviderId={availablePurposesByProviderId}
          fetchPurposesByProviderId={fetchPurposesByProviderId}
          location={location}
          tokens={tokens}
        />
      </div>
      <div className="col-xs-12 text-center hidden-print share-bar">
        <hr className="mt-m mr-xs ml-xs" aria-hidden="true" />
        <ShareProfile
          provider={provider}
          config={config}
          log={log}
          device={device}
        />
      </div>
    </div>
  );
}

export default Summary;
